<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form>
      <div class="row">
        <div class="col-lg-12">
          <div>
            <router-link :to="'/category'" class="btn btn-success mb-3">
              <i class="mdi mdi-arrow-bottom-left-thick me-1"></i> Back
            </router-link>
          </div>
          <div id="header-accordion" class="custom-accordion">
            <!-- Page Genel Ayarlar -->
            <div class="card">
              <div class="p-4 border-top">
                <div class="row">
                  <div class="row border-col" v-for="item in 20" :key="item">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="namePage">Before {{ item }}</label> <br />
                        <img
                          :src="formData['before' + item]"
                          alt=""
                          class="img-fluid"
                          width="150"
                          height="150"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="namePage">After {{ item }}</label> <br />
                        <img
                          :src="formData['after' + item]"
                          alt=""
                          class="img-fluid"
                          width="150"
                          height="150"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="namePage"
                          >Image Before <strong>*</strong></label
                        >
                        <input
                          type="file"
                          class="form-control"
                          @change="fileChange('before' + item, $event)"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="namePage"
                          >Image After <strong>*</strong></label
                        >
                        <input
                          type="file"
                          class="form-control"
                          @change="fileChange('after' + item, $event)"
                          required
                        />
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="namePage"
                          >Before Image Sub Text <strong>*</strong></label
                        >
                        <input
                          v-model="formData['before_sub_text' + item]"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="namePage"
                          >After Image Sub Text <strong>*</strong></label
                        >
                        <input
                          v-model="formData['after_sub_text' + item]"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Page Genel Ayarlar -->
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-end ms-1">
          <button
            type="button"
            class="btn btn-danger"
            :disabled="createSend == 1 ? true : false"
          >
            <i class="uil uil-times me-1"></i> Cancel
          </button>
          <button
            type="submit"
            @click="save()"
            class="btn btn-success ms-1"
            :disabled="createSend == 1 ? true : false"
          >
            <i class="uil uil-file-alt me-1"></i> Save
          </button>
        </div>
        <!-- end col -->
      </div>
    </form>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { API } from "@/state/API";
import { POPUP } from "@/state/popup";

export default {
  page: {
    title: "Category Before After",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Category Before After",
      items: [
        {
          text: "Category Before After",
        },
        {
          text: "Create",
          active: true,
        },
      ],
      createSend: 0,
      formData: {
        before1: null,
        after1: null,
        before_sub_text1: null,
        after_sub_text1: null,
        before2: null,
        after2: null,
        before_sub_text2: null,
        after_sub_text2: null,
        before3: null,
        after3: null,
        before_sub_text3: null,
        after_sub_text3: null,
        before4: null,
        after4: null,
        before_sub_text4: null,
        after_sub_text4: null,
        before5: null,
        after5: null,
        before_sub_text5: null,
        after_sub_text5: null,
        before6: null,
        after6: null,
        before_sub_text6: null,
        after_sub_text6: null,
        before7: null,
        after7: null,
        before_sub_text7: null,
        after_sub_text7: null,
        before8: null,
        after8: null,
        before_sub_text8: null,
        after_sub_text8: null,
        before9: null,
        after9: null,
        before_sub_text9: null,
        after_sub_text9: null,
        before10: null,
        after10: null,
        before_sub_text10: null,
        after_sub_text10: null,
        before11: null,
        after11: null,
        before_sub_text11: null,
        after_sub_text11: null,
        before12: null,
        after12: null,
        before_sub_text12: null,
        after_sub_text12: null,
        before13: null,
        after13: null,
        before_sub_text13: null,
        after_sub_text13: null,
        before14: null,
        after14: null,
        before_sub_text14: null,
        after_sub_text14: null,
        before15: null,
        after15: null,
        before_sub_text15: null,
        after_sub_text15: null,
        before16: null,
        after16: null,
        before_sub_text16: null,
        after_sub_text16: null,
        before17: null,
        after17: null,
        before_sub_text17: null,
        after_sub_text17: null,
        before18: null,
        after18: null,
        before_sub_text18: null, 
        after_sub_text18: null,
        before19: null,
        after19: null,
        before_sub_text19: null,
        after_sub_text19: null,
        before20: null,
        after20: null,
        before_sub_text20: null,
        after_sub_text20: null, 
      },
      value1: null,
      options: [],
      langs: [],
    };
  },
  middleware: "authentication",
  mounted() {
    this.FindLp();
  },
  methods: {
    FindLp() {
      API.Get(API.Category.Photo.UpdateList + this.$route.params.id)
        .then((response) => {
          if (response.data.status == "success") {
            response.data.order.forEach((element, index) => {
              this.formData["before" + (index + 1)] =
                element["before" + (index + 1)];
              this.formData["after" + (index + 1)] =
                element["after" + (index + 1)];
              this.formData["before_sub_text" + (index + 1)] =
                element["before_sub_text" + (index + 1)];
              this.formData["after_sub_text" + (index + 1)] =
                element["after_sub_text" + (index + 1)];
            });

            console.log(this.formData);
          }
        })
        .catch((error) => {
          console.error("Error while fetching data:", error);
        });
    },
    convertToFormData() {
      const _formData = new FormData();

      Object.keys(this.formData).map((key) => {
        _formData.append(key, this.formData[key]);
      });

      return _formData;
    },
    async save() {
      this.createSend = 1;
      var data;
      const response = await API.Post(
        API.Category.Photo.Create + this.$route.params.id,
        this.convertToFormData()
      );
      console.log(response.data);
      console.log(this.formData.before_sub_text1);
      if (response.data.status == "success") {
        this.createSend = 0;

        data = "Your work has update";
        POPUP.popupClick(data);
        this.FindLp();
      } else {
        this.createSend = 0;
        data = "Error";
        POPUP.popupClickNot(data);
      }
    },

    fileChange(fieldName, $event) {
      if ($event.target.files && $event.target.files.length) {
        this.formData[fieldName] = $event.target.files[0];
      }
    },
  },
};
</script>

<style scoped>
strong {
  color: rgba(239, 4, 4, 0.39);
}
.border-col {
  box-shadow: 0px 0px 5px 0px #ddd;
  padding: 10px;
}
</style>
